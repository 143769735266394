<template>
  <v-app style="background: none">

    <!-- SELECT LIGHT/DARK THEME -->
    <top-bar></top-bar>

    <menu-drawer></menu-drawer>

    <v-main :class="logged ? 'mt-6' : ''">
      <router-view @login="login" @logout="logOut" :logged="logged"/>

      <div v-if="logged" class="container-fluid text-center mb-16">
        <hr class="mt-4">
        <img v-if="partner === 'andel'" src="./assets/partners/andel.svg" height="60px">
        <img v-if="partner === 'bestdrive'" src="./assets/partners/bestdrive.svg" height="100px">
        <img v-if="partner === 'cga2'" src="./assets/partners/cga.png" height="100px">
        <img v-if="partner === 'optimacar'" src="./assets/partners/optimacar.png" height="100px">
        <img v-if="partner === 'voltaf'" src="./assets/partners/voltaf.png" height="100px">
      </div>

    </v-main>

    <v-footer
        v-if="logged && theme.powered"
        class="text-center"
        dark
        fixed
    >
      Powered by
      <img src="./assets/logo-next.webp" alt="">
    </v-footer>

    <div class="fixed-bottom-right">

      <v-btn
          class="black--text font-weight-bold"
          color="#EB6C16"
          tile
          small
      >
        <span>{{ version }}</span>
      </v-btn>

    </div>

  </v-app>
</template>

<script>
import settings from "./settings";
import {mapActions, mapState} from "vuex";
import TopBar from "@/components/TopBar.vue";
import MenuDrawer from "@/components/MenuDrawer.vue";

export default {
  name: 'App',
  components: {MenuDrawer, TopBar},
  data() {
    return {
      menu: false,
      phoneMenu: 0,
      userName: '',
      token: '',
      version: settings.VERSION,
    }
  },
  computed: {
    currentPage() {
      return this.$route.path;
    },
    ...mapState([
      'partner',
      'theme',
      'logged'
    ]),
  },
  methods: {
    ...mapActions([
        'logIn',
        'logOut'
    ]),
    login(user) {
      this.logIn()
      this.userName = user;
      this.$router.push('/bulletins')
    },
    setup() {
      this.userName = sessionStorage.username;
      this.token = sessionStorage.token;
      this.$vuetify.theme.themes.light.primary = this.theme.primary
    }
  },
  created() {
    this.setup()
  },
}
</script>

<style lang="scss" scoped>
@import 'theme.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
</style>

<style>

.fixed-bottom-right {
  bottom: 0px;
  right: 0px;
  position: fixed;
  z-index: 99999;
}

.v-btn--active.no-active::before {
  opacity: 0 !important;
}

.top-row {
  background-color: white;
  box-shadow: 0 3px 5px #aaaaaa;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.menu-enter-active {
  transition: all 0.5s ease;
}

.menu-leave-active {
  transition: all 0.5s ease;
}

.menu-enter, .menu-leave-to {
  max-height: 0px;
  overflow: hidden;
}

.menu-enter-to, .menu-leave {
  max-height: 500px;
  overflow: hidden;
}
</style>