<template>
  <div class="container-fluid">
    <v-row>
      <v-col cols="auto" class="d-none d-md-block">
        <history-selector :history="history"></history-selector>
      </v-col>
      <v-col>
        <v-dialog
            v-model="dialog"
            width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="d-block d-md-none mb-3">
              <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
              >
                {{TEXT('latest_10_vehicles')}}
              </v-btn>
              <v-divider></v-divider>
            </div>

          </template>

          <history-selector :history="history"></history-selector>
        </v-dialog>
        <v-row class="pt-2 justify-center">
          <v-col cols="auto" class="px-1 py-1"
                 v-for="maker in makers" :key="maker.id">
            <v-card elevation="3">
              <v-card-text class="pa-2">
                <v-img
                    :src="'https://assets.infotechnic.es/logos/'+maker.name.toLowerCase()+'.png'"
                    @click="setMaker(maker)"
                    style="cursor: pointer"
                >
                </v-img>
              </v-card-text>
              <v-card-actions class="primary white--text justify-center">
                {{
                  maker.name
                }}
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import api from "@/api";
import HistorySelector from "@/components/bulletins/HistorySelector.vue";
import {mapGetters} from "vuex";

export default {
  name: "MakerSelector",
  components: {HistorySelector},
  data() {
    return {
      makers: [],
      history: [],
      dialog: false
    }
  },
  computed: {
    ...mapGetters(['TEXT']),
  },
  methods: {
    listHistory() {
      api.GET_HISTORY()
          .then(response => {
            if (!response.data.auth) {
              this.$emit('logout')
            } else {
              this.history = response.data.list
            }
          });
    },
    listMakers() {
      api.GET_MAKERS()
          .then(response => {
            if (!response.data.auth) {
              this.$emit('logout')
            } else {
              this.makers = response.data.list
              this.listHistory();
            }
          });
    },
    setMaker(maker) {
      let vehicle = {}
      vehicle.maker = maker.name
      vehicle.makerID = maker.id
      this.$store.commit('SET_VEHICLE', vehicle)
      this.$store.commit('CHANGE_TSB_VIEW', 1)
    },
  },
  created() {
    this.listMakers();
  },

}
</script>